<template>
    <v-container>
        <!-- <div class="title-container">
            <v-row>
                <h1>{{ slides[slide].title }}</h1>
            </v-row>
            <v-row>
                <h2>{{ slides[slide].subtitle }}</h2>
            </v-row>
            <v-row v-if="slides[slide].actionText">
                <h2>{{ slides[slide].actionText }}</h2>
            </v-row>
        </div> -->

        <v-btn elevation="5" icon @click.stop="drawer = !drawer"
            ><v-icon>{{ icons.mdiMenu }}</v-icon></v-btn
        >

        <v-navigation-drawer v-model="drawer" absolute temporary :width="325">
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title
                        ><v-icon>{{ icons.mdiPackageVariantClosed }} </v-icon> Win Curriculum</v-list-item-title
                    >
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item-group v-model="selectedNavItem">
                    <v-list-item v-for="(slideDetails, s) in slides" :key="s" link>
                        <v-list-item-content @click="goToSlide(s)">
                            <v-list-item-title>{{ slideDetails.title }}</v-list-item-title>
                            <v-list-item-subtitle>{{ slideDetails.subtitle }}</v-list-item-subtitle>
                            <v-list-item-subtitle>Page: {{ s + 1 }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-container class="carousel-container">
            <v-carousel
                @change="playAudio(slide)"
                v-model="slide"
                hide-delimiters
                :show-arrows="false"
                height="auto"
                style="z-index: 5"
            >
                <v-carousel-item v-for="(slide, s) in slides" :key="s">
                    <v-img :src="slide.src" v-if="slide.type === 'slide'" class="slide-img" contain>
                        <div class="title-container">
                            <v-row>
                                <h1>{{ slide.title }}</h1>
                            </v-row>
                            <v-row>
                                <h2>{{ slide.subtitle }}</h2>
                            </v-row>
                            <v-row v-if="slide.actionText">
                                <h2>{{ slide.actionText }}</h2>
                            </v-row>
                        </div>
                    </v-img>

                    <div v-if="slide.type === 'quiz'">
                        <v-radio-group v-for="q in slide.questions" :key="q.question" v-model="q.selectedAnswer">
                            {{ q.question }}
                            <v-radio v-for="a in q.answers" :key="a" :label="a" :value="a"> </v-radio>
                        </v-radio-group>

                        {{ slide.score }}
                        <v-btn @click="submitQuiz(slide)" color="blue">submit</v-btn>
                    </div>
                </v-carousel-item>
            </v-carousel>

            <div class="controls-container">
                <v-btn fab small class="slide-btn" @click="back"
                    ><v-icon>{{ icons.mdiChevronLeft }}</v-icon></v-btn
                >
                <v-avatar color="light-grey" raised small size="56"> {{ slide + 1 }} / {{ slides.length }} </v-avatar>
                <v-btn fab small class="slide-btn" @click="forward"
                    ><v-icon>{{ icons.mdiChevronRight }}</v-icon></v-btn
                >
            </div>

            <v-btn fab small class="audio-toggle" @click="toggleAudio"
                ><v-icon>{{ doPlayAudio ? icons.mdiVolumeHigh : icons.mdiVolumeOff }}</v-icon></v-btn
            >

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style="position: absolute; left: 0; bottom: -30">
                <path
                    fill="#456990"
                    fill-opacity="0.8"
                    d="M0,192L80,213.3C160,235,320,277,480,256C640,235,800,149,960,133.3C1120,117,1280,171,1360,197.3L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                ></path>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style="position: absolute; left: 0; bottom: -30">
                <path
                    fill="#456990"
                    fill-opacity="0.8"
                    d="M0,160L80,181.3C160,203,320,245,480,256C640,267,800,245,960,234.7C1120,224,1280,224,1360,224L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                ></path>
            </svg>
        </v-container>
    </v-container>
</template>

<script>
import { Howl } from "howler";
import {
    mdiHeartOutline,
    mdiVolumeHigh,
    mdiVolumeOff,
    mdiChevronRight,
    mdiChevronLeft,
    mdiMenu,
    mdiPackageVariantClosed,
} from "@mdi/js";

let convertMinsToSeconds = function (input) {
    var parts = input.split(":"),
        minutes = +parts[0],
        seconds = +parts[1];
    return (minutes * 60 + seconds).toFixed(3);
};

const moduleId = 0;
let userModuleKey = null;

// quiz id to slide # mappings
let quizes = {
    1: 26,
    2: 42,
    3: 59,
};

let slides = [
    {
        title: "Introduction",
        subtitle: "Welcome to the WIN model curriculum",
        src: require("@/assets/slides/v5/Slide1.png"),
        type: "slide",
        srcFile: "1",
        startTime: 0,
        stopAt: 20,
    },
    {
        title: "The WIN Model",
        subtitle: "Overview Slide 1",
        src: require("@/assets/slides/v5/Slide2.png"),
        type: "slide",
        srcFile: "1",
        startTime: 20,
        stopAt: 48,
    },
    {
        title: "The WIN Model",
        subtitle: "Overview Slide 2",
        src: require("@/assets/slides/v5/Slide3.png"),
        type: "slide", //49 start 2:02
        srcFile: "1",
        startTime: 48,
        stopAt: convertMinsToSeconds("2:03"),
    },
    {
        title: "The WIN Model",
        subtitle: "Overview Slide 3",
        src: require("@/assets/slides/v5/Slide4.png"),
        type: "slide", // 2:02 start: 3:38
        srcFile: "1",
        startTime: convertMinsToSeconds("2:03"),
        stopAt: convertMinsToSeconds("3:38"),
    },
    {
        title: "The WIN Model",
        subtitle: "Overview Slide 4", //new
        src: require("@/assets/slides/v5/Slide5.png"),
        type: "slide", // 2:02 start: 3:38
        srcFile: "1",
        startTime: convertMinsToSeconds("2:03"),
        stopAt: convertMinsToSeconds("3:38"),
    },
    {
        title: "The WIN Model",
        subtitle: "Overview Slide 5", //new
        src: require("@/assets/slides/v5/Slide6.png"),
        type: "slide", // 2:02 start: 3:38
        srcFile: "1",
        startTime: convertMinsToSeconds("2:03"),
        stopAt: convertMinsToSeconds("3:38"),
    },
    {
        title: "Workforce Development",
        subtitle: "Overview slide 1",
        src: require("@/assets/slides/v5/Slide7.png"),
        type: "slide", // endAt 4:30
        srcFile: "1",
        startTime: convertMinsToSeconds("3:38"),
        stopAt: convertMinsToSeconds("4:28"),
    },
    {
        title: "Workforce Development",
        subtitle: "Overview slide 2",
        src: require("@/assets/slides/v5/Slide8.png"),
        type: "slide", // endAt 5:50
        srcFile: "1",
        startTime: convertMinsToSeconds("4:29"),
        stopAt: convertMinsToSeconds("5:49"),
    },
    {
        title: "Workforce Development",
        subtitle: "Overview slide 3",
        src: require("@/assets/slides/v5/Slide9.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("5:49"),
        stopAt: convertMinsToSeconds("6:45"),
        audioClips: [
            {
                startTime: convertMinsToSeconds("5:49"),
                stopAt: convertMinsToSeconds("5:55"),
            },
            {
                startTime: convertMinsToSeconds("5:58"),
                stopAt: convertMinsToSeconds("6:45"),
            },
        ],
    },
    {
        title: "Workforce Development",
        subtitle: "Overview slide 4",
        src: require("@/assets/slides/v5/Slide10.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("6:45"),
        stopAt: convertMinsToSeconds("8:16"),
        audioClips: [
            {
                startTime: convertMinsToSeconds("6:45"),
                stopAt: convertMinsToSeconds("6:52"),
            },
            {
                startTime: convertMinsToSeconds("6:53"),
                stopAt: convertMinsToSeconds("6:45"),
            },
        ],
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Reading",
        src: require("@/assets/slides/v5/Slide11.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("8:16"),
        stopAt: convertMinsToSeconds("9:40"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Reading",
        src: require("@/assets/slides/v5/Slide12.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("9:40"),
        stopAt: convertMinsToSeconds("11:00"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Reading",
        src: require("@/assets/slides/v5/Slide13.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("11:00"),
        stopAt: convertMinsToSeconds("12:18"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Reading",
        src: require("@/assets/slides/v5/Slide14.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("12:18"),
        stopAt: convertMinsToSeconds("13:21"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Reading",
        src: require("@/assets/slides/v5/Slide15.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("13:21"),
        stopAt: convertMinsToSeconds("14:32"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Reading",
        src: require("@/assets/slides/v5/Slide16.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("14:32"),
        stopAt: convertMinsToSeconds("15:59"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Education",
        src: require("@/assets/slides/v5/Slide17.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("15:59"),
        stopAt: convertMinsToSeconds("17:23"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Education",
        src: require("@/assets/slides/v5/Slide18.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("17:23"),
        stopAt: convertMinsToSeconds("18:41"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Education",
        src: require("@/assets/slides/v5/Slide19.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("18:41"),
        stopAt: convertMinsToSeconds("19:58"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Education",
        src: require("@/assets/slides/v5/Slide20.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("19:58"),
        stopAt: convertMinsToSeconds("21:18"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Education",
        src: require("@/assets/slides/v5/Slide21.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("21:18"),
        stopAt: convertMinsToSeconds("22:40"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Work",
        src: require("@/assets/slides/v5/Slide22.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("22:40"),
        stopAt: convertMinsToSeconds("24:03"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Work",
        src: require("@/assets/slides/v5/Slide23.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("24:03"),
        stopAt: convertMinsToSeconds("25:24"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Work",
        src: require("@/assets/slides/v5/Slide24.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("25:24"),
        stopAt: convertMinsToSeconds("26:44"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Work",
        src: require("@/assets/slides/v5/Slide25.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("26:44"),
        stopAt: convertMinsToSeconds("27:57"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Work",
        src: require("@/assets/slides/v5/Slide26.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("27:57"),
        stopAt: convertMinsToSeconds("29:15"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Work",
        src: require("@/assets/slides/v5/Slide27.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("29:15"),
        stopAt: convertMinsToSeconds("30:38"),
    },
    {
        title: "Workforce Development",
        subtitle: "Life Principal: Work",
        src: require("@/assets/slides/v5/Slide28.png"),
        type: "slide",
        srcFile: "1",
        startTime: convertMinsToSeconds("30:38"),
        stopAt: convertMinsToSeconds("32:00"),
    },
    // {
    //     title: 'Workforce Development',
    //     subtitle: 'Life Principal: Work',
    //     src: require('@/assets/slides/v5/Slide27.png'),
    //     type: 'slide',
    //     srcFile: '1',
    //     startTime: convertMinsToSeconds('32:00'),
    //     stopAt: convertMinsToSeconds('32:54'),
    // },
    {
        title: "Workforce Development Model",
        subtitle: "You have completed the Workforce Development Model.",
        actionText: "As a refresher, please answer the following questions:",
        type: "quiz",
        srcFile: "1",
        quizId: 1,
        startTime: convertMinsToSeconds("32:00"),
        stopAt: convertMinsToSeconds("32:54"),
        didComplete: false,
        questions: [
            {
                questionId: 1,
                question: "The United States is:",
                answers: [
                    "A poor nation",
                    "The most prosperous nation in the entire world",
                    "The second wealthiest nation in the world",
                ],
                selectedAnswer: null,
                answer: "The most prosperous nation in the entire world",
            },
            {
                questionId: 2,
                question: "The WIN Model includes the following three modules:",
                answers: [
                    "Working, Intelligence, and Napping",
                    "Welfare, Importance, and Neglect",
                    "Workforce-Development, Integrity, and Next Generation",
                ],
                selectedAnswer: null,
                answer: "Workforce-Development, Integrity, and Next Generation",
            },
            {
                questionId: 3,
                question: "It is estimated that:",
                answers: [
                    "2 million adults in the United States are illterate",
                    "41 Million Adults in the United States are illiterate or functionally illerate",
                    "45 million babies are considered illiterate in the United States",
                ],
                selectedAnswer: null,
                answer: "41 Million Adults in the United States are illiterate or functionally illerate",
            },
        ],
        score: null,
    },
    {
        title: "Integrity Module",
        subtitle: "Overview Slide 1",
        src: require("@/assets/slides/v5/Slide30.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("00:00"),
        stopAt: convertMinsToSeconds("01:22"),
    },
    {
        title: "Integrity Module",
        subtitle: "Overview Slide 2",
        src: require("@/assets/slides/v5/Slide31.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("01:22"),
        stopAt: convertMinsToSeconds("02:00"),
    },
    {
        title: "Integrity Module",
        subtitle: "Overview Slide 3",
        src: require("@/assets/slides/v5/Slide32.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("02:00"),
        stopAt: convertMinsToSeconds("03:27"),
    },
    {
        title: "Integrity Module",
        subtitle: "Life Principle: Accountability",
        src: require("@/assets/slides/v5/Slide33.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("03:27"),
        stopAt: convertMinsToSeconds("04:44"),
    },
    {
        title: "Integrity Module",
        subtitle: "Life Principle: Accountability",
        src: require("@/assets/slides/v5/Slide34.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("04:44"),
        stopAt: convertMinsToSeconds("06:01"),
    },
    {
        title: "Integrity Module",
        subtitle: "Life Principle: Accountability",
        src: require("@/assets/slides/v5/Slide35.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("06:01"),
        stopAt: convertMinsToSeconds("07:29"),
    },
    {
        title: "Integrity Module",
        subtitle: "Life Principle: Accountability",
        src: require("@/assets/slides/v5/Slide36.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("07:29"),
        stopAt: convertMinsToSeconds("08:58"),
    },
    {
        title: "Integrity Module",
        subtitle: "Life Principle: Respect",
        src: require("@/assets/slides/v5/Slide37.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("08:58"),
        stopAt: convertMinsToSeconds("10:21"),
    },
    {
        title: "Integrity Module",
        subtitle: "Life Principle: Accountability",
        src: require("@/assets/slides/v5/Slide38.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("10:21"),
        stopAt: convertMinsToSeconds("11:47"),
    },
    {
        title: "Integrity Module",
        subtitle: "Life Principle: Respect",
        src: require("@/assets/slides/v5/Slide39.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("11:47"),
        stopAt: convertMinsToSeconds("13:13"),
    },
    {
        title: "Integrity Module",
        subtitle: "Life Principle: Respect",
        src: require("@/assets/slides/v5/Slide40.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("13:13"),
        stopAt: convertMinsToSeconds("14:32"),
    },
    {
        title: "Integrity Module",
        subtitle: "Life Principle: Respect",
        src: require("@/assets/slides/v5/Slide41.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("14:32"),
        stopAt: convertMinsToSeconds("15:52"),
    },
    {
        title: "Integrity Module",
        subtitle: "Life Principle: Respect",
        src: require("@/assets/slides/v5/Slide42.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("15:52"),
        stopAt: convertMinsToSeconds("17:11"),
    },
    {
        title: "Integrity Module",
        subtitle: "Life Principle: Respect",
        src: require("@/assets/slides/v5/Slide43.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("17:11"),
        stopAt: convertMinsToSeconds("18:24"),
    },
    {
        title: "Integrity Module",
        subtitle: "Life Principle: Respect",
        src: require("@/assets/slides/v5/Slide44.png"),
        type: "slide",
        srcFile: "2",
        startTime: convertMinsToSeconds("18:24"),
        stopAt: convertMinsToSeconds("19:49"),
    },
    {
        title: "Integrity Module",
        subtitle: "You have completed WIN Integrity Module",
        actionText: "As a refresher, please answer the following questions:",
        type: "quiz",
        srcFile: "2",
        quizId: 2,
        didComplete: false,
        startTime: convertMinsToSeconds("19:49"),
        stopAt: convertMinsToSeconds("20:43"),
        questions: [
            {
                question: "The Integrity Module includes the following life principles",
                answers: ["Reading, Education, Work", "Dressing and Accountability", "Respect and Accountability"],
                selectedAnswer: null,
                answer: "Respect and Accountability",
            },
            {
                question: "Your Integrity will be measured by:",
                answers: ["You", "The days it rains", "Everybody you encounter"],
                selectedAnswer: null,
                answer: "Everybody you encounter",
            },
            {
                question:
                    "When it comes to your Integrity, others will be judge you based on the following. Check all that apply",
                answers: ["The way you look", "The way you dress", "The way you smell", "All of the above"],
                selectedAnswer: null,
                answer: "All of the above",
            },
        ],
        score: null,
    },
    // {
    //     title: null,
    //     subtitle: null,
    //     src: require('@/assets/slides/v5/Slide43.png'),
    //     type: 'slide',
    // },
    {
        title: "Next Generation Module",
        subtitle: "Overview Slide 1",
        src: require("@/assets/slides/v5/Slide46.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("00:00"),
        stopAt: convertMinsToSeconds("01:04"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Overview Slide 2",
        src: require("@/assets/slides/v5/Slide47.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("01:04"),
        stopAt: convertMinsToSeconds("02:35"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Overview Slide 3",
        src: require("@/assets/slides/v5/Slide48.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("02:35"),
        stopAt: convertMinsToSeconds("03:52"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Overview Slide 4",
        src: require("@/assets/slides/v5/Slide49.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("03:52"),
        stopAt: convertMinsToSeconds("05:29"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Life Principle: Duty",
        src: require("@/assets/slides/v5/Slide50.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("05:29"),
        stopAt: convertMinsToSeconds("06:52"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Life Principle: Duty",
        src: require("@/assets/slides/v5/Slide51.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("06:52"),
        stopAt: convertMinsToSeconds("08:12"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Life Principle: Duty",
        src: require("@/assets/slides/v5/Slide52.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("08:12"),
        stopAt: convertMinsToSeconds("09:36"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Life Principle: Duty",
        src: require("@/assets/slides/v5/Slide53.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("09:36"),
        stopAt: convertMinsToSeconds("10:58"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Life Principle: Duty",
        src: require("@/assets/slides/v5/Slide54.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("10:58"),
        stopAt: convertMinsToSeconds("12:22"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Life Principle: Duty",
        src: require("@/assets/slides/v5/Slide55.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("12:22"),
        stopAt: convertMinsToSeconds("13:44"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Life Principle: Saving",
        src: require("@/assets/slides/v5/Slide56.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("13:44"),
        stopAt: convertMinsToSeconds("15:08"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Life Principle: Saving",
        src: require("@/assets/slides/v5/Slide57.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("15:08"),
        stopAt: convertMinsToSeconds("16:29"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Life Principle: Saving",
        src: require("@/assets/slides/v5/Slide58.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("16:29"),
        stopAt: convertMinsToSeconds("17:51"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Life Principle: Saving",
        src: require("@/assets/slides/v5/Slide59.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("17:51"),
        stopAt: convertMinsToSeconds("19:06"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Life Principle: Saving",
        src: require("@/assets/slides/v5/Slide60.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("19:06"),
        stopAt: convertMinsToSeconds("20:22"),
    },
    {
        title: "Next Generation Module",
        subtitle: "Life Principle: Saving",
        src: require("@/assets/slides/v5/Slide61.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("20:22"),
        stopAt: convertMinsToSeconds("21:41"),
    },
    {
        title: "Next Generation Module",
        subtitle: "You have completed the WIN Next Generation module.",
        actionText: "As a refresher, please answer the following questions:",
        type: "quiz",
        type: "quiz",
        srcFile: "3",
        quizId: 2,
        didComplete: false,
        startTime: convertMinsToSeconds("21:41"),
        stopAt: convertMinsToSeconds("23:00"),
        questions: [
            {
                question: "Living in the United States is all about:",
                answers: ["Being able to visit all 50 states", "The money", "Being social with others"],
                selectedAnswer: null,
                answer: "The money",
            },
            {
                question:
                    "In terms of the Next Generation of United States citizens, what is the most critically important concern “today?”",
                answers: [
                    "Appropriately and purposefully guiding our children on their trajectory to  the future.",
                    "Planning for the New York GIANTS to win the Superbowl at least 5 times.",
                    "Adults making as much money as they possibly can.",
                ],
                selectedAnswer: null,
                answer: "Appropriately and purposefully guiding our children on their trajectory to  the future.",
            },
            {
                question: "The Next Generation Module has two life principles. What are they?",
                answers: ["Reading and Education", "Work and Saving", "Duty and Saving"],
                selectedAnswer: null,
                answer: "Duty and Saving",
            },
        ],
        score: null,
    },
    {
        title: "Key Takeaways",
        subtitle: "Recap",
        src: require("@/assets/slides/v5/Slide63.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("17:51"),
        stopAt: convertMinsToSeconds("19:06"),
    },
    {
        title: "Key Takeaways",
        subtitle: "Recap",
        src: require("@/assets/slides/v5/Slide64.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("17:51"),
        stopAt: convertMinsToSeconds("19:06"),
    },
    {
        title: "Key Takeaways",
        subtitle: "Recap",
        src: require("@/assets/slides/v5/Slide65.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("17:51"),
        stopAt: convertMinsToSeconds("19:06"),
    },
    {
        title: "Final Exam",
        subtitle: "You have completed the WIN Next Generation module.",
        actionText: "As a refresher, please answer the following questions:",
        type: "quiz",
        type: "quiz",
        srcFile: "3",
        quizId: 2,
        didComplete: false,
        startTime: convertMinsToSeconds("21:41"),
        stopAt: convertMinsToSeconds("23:00"),
        questions: [
            {
                question:
                    "The WIN Model includes the following three modules: Workforce-Development, Integrity and Next Generation.",
                answers: ["true", "false"],
                selectedAnswer: null,
                answer: "true",
            },
            {
                question: "All children must start reading prior to attending First Grade.",
                answers: ["true", "false"],
                selectedAnswer: null,
                answer: "true",
            },
            {
                question:
                    "The Workforce-Development Module includes the following life principles:  Reading, Education and Work.           ",
                answers: ["true", "false"],
                selectedAnswer: null,
                answer: "true",
            },
            {
                question:
                    "Learn at least five new skills that can be leveraged to earn money prior to graduating from high school. Consider these STEM areas of focus Science, Technology, Engineering and Math. Also consider Sports, landscaping, construction, corporate, acting, writing, housekeeping, painting, electrical, plumbing and so on.",
                answers: ["true", "false"],
                selectedAnswer: null,
                answer: "true",
            },
            {
                question:
                    "When you graduate from high school, be prepared to work and earn money or start the process of attaining higher education - college.",
                answers: ["true", "false"],
                selectedAnswer: null,
                answer: "true",
            },
            {
                question: "The Integrity Module includes the following life principles: Respect and Accountability. ",
                answers: ["true", "false"],
                selectedAnswer: null,
                answer: "true",
            },
            {
                question:
                    "Stay out of trouble. Never do anything that may get you arrested or thrown in jail. Never steal anything - EVER! ",
                answers: ["true", "false"],
                selectedAnswer: null,
                answer: "true",
            },
            {
                question: "Command respect! Be sure your friends are people who at least share your values. ",
                answers: ["true", "false"],
                selectedAnswer: null,
                answer: "true",
            },
            {
                question: "The Next Generation Module includes the following life principles: Duty and Saving Money. ",
                answers: ["true", "false"],
                selectedAnswer: null,
                answer: "true",
            },
            {
                question: "Start saving money when you are seven years old and for the rest of your life. ",
                answers: ["true", "false"],
                selectedAnswer: null,
                answer: "true",
            },
            {
                question: "Create a plan for you to become a millionaire so you can share your generational wealth. ",
                answers: ["true", "false"],
                selectedAnswer: null,
                answer: "true",
            },
        ],
        score: null,
    },
    {
        title: "Certificate of completion",
        subtitle: null,
        src: require("@/assets/slides/v5/Slide67.png"),
        type: "slide",
        srcFile: "3",
        startTime: convertMinsToSeconds("20:22"),
        stopAt: convertMinsToSeconds("21:41"),
    },
];

import { userModulesCollection, userQuizesCollection } from "../../db";

export default {
    name: "Courses",
    setup() {
        return {
            icons: {
                mdiHeartOutline,
                mdiVolumeHigh,
                mdiVolumeOff,
                mdiChevronRight,
                mdiChevronLeft,
                mdiMenu,
                mdiPackageVariantClosed,
            },
        };
    },
    beforeDestroy() {
        if (this.doPlayAudio === true && this.currentTrack) {
            this.currentTrack.stop();
        }
    },
    mounted() {
        const userData = JSON.parse(localStorage.getItem("userData"));
        userModuleKey = userData.uid + "|" + moduleId;

        userModulesCollection
            .doc(userModuleKey)
            .get()
            .then((snapshot) => {
                if (snapshot.exists === false) {
                    // first time doing the course

                    userModulesCollection
                        .doc(userModuleKey)
                        .set({
                            didComplete: false,
                            didStart: true,
                            lastSlideNumber: 0,
                        })
                        .then(() => {
                            console.log("initialized user module");

                            this.playAudio(0);
                        });
                } else {
                    this.slide = snapshot.data().lastSlideNumber;
                    this.playAudio(this.slide);
                }
            });

        for (let quizId in quizes) {
            console.log(quizId);

            let userQuizKey = userModuleKey + "|" + quizId;

            userQuizesCollection
                .doc(userQuizKey)
                .get()
                .then((snapshot) => {
                    if (snapshot.exists) {
                        slides[quizes[quizId]].score = snapshot.data().score;
                        slides[quizes[quizId]].didComplete = snapshot.data().didComplete;
                    }
                });

            userQuizesCollection
                .doc(userQuizKey)
                .collection("answers")
                .get()
                .then((querySnapshot) => {
                    for (let i = 0, l = querySnapshot.docs.length; i < l; i++) {
                        slides[quizes[quizId]].questions[i].selectedAnswer = querySnapshot.docs[i].data().selectedAnswer;
                    }
                });
        }
    },
    methods: {
        playAudioAsync: async function (slideObj, sound) {
            const timer = (ms) => new Promise((res) => setTimeout(res, ms));

            for (let clip in slideObj.audioClips) {
                sound.seek(slideObj.audioClips[clip].startTime);
                sound.play();

                if (this.timeout) {
                    clearTimeout(this.timeout);
                }

                let timeoutLength = slideObj.audioClips[clip].stopAt - slideObj.audioClips[clip].startTime;

                this.timeout = setTimeout(function () {
                    console.log("timeout");
                    console.log(sound);
                    sound.pause();
                }, timeoutLength * 1000);

                this.currentTrack = sound;

                await timer(timeoutLength * 1000);
            }
        },
        playAudio: function (slide) {
            let slideObj = this.slides[slide];

            if (this.doPlayAudio) {
                if (this.currentTrack !== null) {
                    this.currentTrack.stop();
                }
                slideObj.srcFil;

                let sound = new Howl({
                    src: [
                        "https://storage.googleapis.com/elearn-2e113.appspot.com/Courses/winmodel/audio/" +
                            slideObj.srcFile +
                            ".wav",
                    ],
                    html5: true,
                });

                // what we're trying to do is make it so it will play each group of times individually in a sequence

                // if (slideObj.audioClips) {
                //     this.playAudioAsync(slideObj, sound);
                // } else {
                sound.seek(slideObj.startTime);
                sound.play();
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                let timeoutLength = slideObj.stopAt - slideObj.startTime;
                this.timeout = setTimeout(function () {
                    console.log("timeout");
                    console.log(sound);
                    sound.pause();
                }, timeoutLength * 1000);
                this.currentTrack = sound;
                //}
            }
        },
        toggleAudio: function () {
            this.doPlayAudio = !this.doPlayAudio;

            if (this.currentTrack !== null) {
                if (this.doPlayAudio === false) {
                    this.currentTrack.pause();
                } else {
                    this.currentTrack.play();
                }
            } else if (this.doPlayAudio) {
                this.playAudio(this.slide);
            }
        },
        back: function () {
            if (this.slide !== 0) {
                this.slide--;

                this.updateUserSlide();
                this.playAudio(this.slide);
            }
        },
        forward: function () {
            if (this.slides.length != this.slide + 1) {
                this.slide++;

                this.updateUserSlide();
                this.playAudio(this.slide);
            }
        },
        goToSlide: function (index) {
            this.drawer = false;
            this.slide = index;

            this.updateUserSlide();
            this.playAudio(this.slide);
        },
        submitQuiz: function (slideObj) {
            let totalQuestions = slideObj.questions.length,
                questionsCorrect = 0;

            let userQuizKey = userModuleKey + "|" + slideObj.quizId;

            for (let i = 0, l = slideObj.questions.length; i < l; i++) {
                if (slideObj.questions[i].selectedAnswer === slideObj.questions[i].answer) {
                    questionsCorrect++;
                }

                userQuizesCollection
                    .doc(userQuizKey)
                    .collection("answers")
                    .doc(i.toString())
                    .set({
                        selectedAnswer: slideObj.questions[i].selectedAnswer,
                    })
                    .then(() => {
                        console.log("updated answer");
                    });
            }

            slideObj.score = Math.ceil((questionsCorrect / totalQuestions) * 100) + "%";

            userQuizesCollection
                .doc(userQuizKey)
                .set({
                    didComplete: true,
                    score: slideObj.score,
                })
                .then(() => {
                    console.log("updated parent quiz");
                });
        },
        updateUserSlide: function () {
            userModulesCollection
                .doc(userModuleKey)
                .set(
                    {
                        lastSlideNumber: this.slide,
                    },
                    {
                        merge: true,
                    }
                )
                .then(() => {
                    console.log("updated user slide");
                });
        },
        updateUserQuiz: function () {},
    },
    data() {
        return {
            // icons: [mdiHeartOutline, mdiVolumeHigh, mdiVolumeOff, mdiChevronRight, mdiChevronLeft],
            currentTrack: null,
            slide: 0,
            doPlayAudio: true,
            currentTimeout: null,
            selectedNavItem: 0,
            slides: slides,
            drawer: null,
            items: [
                { title: "Home", icon: "mdi-view-dashboard" },
                { title: "About", icon: "mdi-forum" },
            ],
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title-container {
    padding: 25px 0px 20px 20px;
    min-height: 63px;
    line-height: 30px;
    background-color: #456990;
    color: #fff;
    height: 80px;
    flex-direction: column;
    display: flex;
    margin-bottom: 500px;
    width: 100%;
    margin-top: -150px;
    position: absolute;

    h1,
    h2,
    h3 {
        padding-left: 10px;
    }
}

.progress-display {
    color: #fff;
    position: absolute;
    left: 48%;
    bottom: -15px;
}

.controls-container {
    position: absolute;
    color: #fff;

    bottom: -15px;
    left: 44%;
    width: 100%;
    z-index: 5;

    .slide-btn:focus::before {
        opacity: 0 !important;
    }
}

.slide-img {
    width: 100%;
    height: 100%;
    padding-top: 150px;
}

.carousel-container {
    // display: flex;
    // align-items: center;
    // margin-top: 10px;
    padding-top: 35px;

    .v-image__image--cover {
        background-size: contain;
    }
}

.audio-toggle {
    position: absolute;
    right: 20px;
    bottom: -5px;
    z-index: 5;
}
</style>
